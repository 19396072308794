<template>
  <oib-page style="z-index: 4">
    <oib-nav-student title="Bieb kiezen" :hide_bottom_on_mobile="true">
      <template v-slot:right>
        <oib-button-icon
        icon="arrow_forward"
        ref="refresh"
        @click="go_to_move_books"
        class="fade"
        style="font-size: 24px;"
        :style="library_id_list_from.length > 0 && library_id_to != null ? 'color: var(--highlight-color);': ''"
        />
      </template>
    </oib-nav-student>
    <oib-content>
      <div class="wrapper row" style="justify-content: space-between;">
        <div class="column">
          <oib-list-item-avatar v-for="library_id in library_id_list_from" :key="library_id"
            style="padding-bottom: 20px;"
            :image_url="get_image_url(library_id)"
            :header="library_dict[library_id].library_name"
            :text="library_dict[library_id].location_name"
          />
          
          <div class="log-book-row-main">
            <div
            class="img-frame"
            style="margin-right: 20px;"
            @click="library_picker_action = add_library_id_from"
            >
              <div class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: var(--primary-color-p4); flex-direction: column;`">
                <p style="font-size: 30px; color: var(--contrast-color-p3); font-weight: 400; margin: 0;">+</p>
              </div>
            </div>
            <div class="log-book-row-details">
              <h3 class="h4">Van</h3>
              <p>Kies een bieb</p>
            </div>
          </div>
        </div>
        <h1 style="margin: 0; height: 0">→</h1>
        <div class="column" style="align-items: flex-end;">
          <div v-if="library_id_to == null" class="log-book-row-main">
            <div class="log-book-row-details" style="text-align: right;">
              <h3 class="h4">Naar</h3>
              <p>Kies een bieb</p>
            </div>
            <div
            class="img-frame"
            style="margin-left: 20px;"
            @click="library_picker_action = set_library_id_to"
            >
              <div class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: var(--primary-color-p4); flex-direction: column;`">
                <p style="font-size: 30px; color: var(--contrast-color-p3); font-weight: 400; margin: 0;">+</p>
              </div>
            </div>
          </div>
          <oib-list-item-avatar-reverse
            v-if="library_id_to != null"
            :image_url="get_image_url(library_id_to)"
            :header="library_dict[library_id_to].library_name"
            :text="library_dict[library_id_to].location_name"
            @click="library_picker_action = set_library_id_to"
          />
        </div>
      </div>
      <div class="wrapper row" style="justify-content: space-between; align-items: center;">
      </div>
      <div v-if="library_picker_action != null" style="margin-top: 10px; margin-bottom: 100px;">
        <div v-if="library_id_list.length == 0" class="wrapper">
          <p class="description">Je kunt geen bieb kiezen omdat er nog geen bieb is toegevoegd. Lees ook: 
            <a href="https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#bibliotheken_toevoegen" target="_blank">
              Hoe voeg ik een nieuwe bieb toe?
            </a>
          </p>
        </div>
        <div v-for="library_id in library_id_list" :key="library_id">
          <oib-list-item-button
            v-if="!library_id_list_from.includes(library_id) && library_id_to!=library_id"
            :image_url="get_image_url(library_id)"
            :header="library_dict[library_id].library_name"
            :text="library_dict[library_id].location_name"
            button_text="selecteren"
            @click="submit(library_id)"
          />
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibListItemButton from "../components/oib_list_item_button.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue";
import OibListItemAvatar from "../components/oib_list_item_avatar.vue";
import OibListItemAvatarReverse from "../components/oib_list_item_avatar_reverse.vue";

import {get_bucket} from "../store/bucket.js"

export default {
  name: "BibliotheekKiezen",
  props: {
    user_id: {
      required: true
    },
    enable_option_all: {
      type: Boolean,
      default: false
    },
    physical_access_only: {
      type: Boolean,
      default: false
    }
  },
  emits: ["input"],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibListItemButton,
    OibButtonIcon,
    OibListItemAvatar,
    OibListItemAvatarReverse
  },
  data() {
    return {
      library_id_to: null,
      library_id_list: [],
      library_id_list_from: [],
      library_dict: {},
      library_picker_action: null      
    }
  },
  created() {
    this.init_library_list()
  },
  methods: {
    async reload_libraries() {
      await reload_libraries()
      this.init_library_list()
      this.$refs.refresh.$el.classList.add('rotate')
      setTimeout(this.stop_refresh_anim, 200)
    },
    stop_refresh_anim() {
      this.$refs.refresh.$el.classList.remove('rotate')
    },
    init_library_list() {
      // Ignore on refresh
      const user = this.$store.getters.getUser
      if(!this.$store.getters.getUser) {
        return
      }

      const role_list = user['role']

      this.library_dict = this.get_library_dict()

      // Add all option to library_id_list
      this.library_id_list = []
      if (this.enable_option_all) {
        this.library_id_list.push(0)
      }

      if (this.filter_roles(['SYSTEM_ADMIN', 'READING_CONSULTANT', 'BOOKSHOP_OWNER', 'LIBRARIAN', 'LIBRARY_ASSISTENT'], role_list)) {
        const library_id_list_all = Object.keys(this.library_dict)
        library_id_list_all.forEach(x => {
            if (x != '0') {
              this.library_id_list.push(x)
            }
        })
      }
      else if (!this.filter_roles(['LIBRARIAN', 'LIBRARY_ASSISTENT'], role_list) && this.physical_access_only) {
        
        // Get library_id_list to which the user has access   
        const access_dict = this.$store.getters.get_user_access_dict[this.user_id]
        if (!access_dict) {
          return
        }

        // Get all libraries in own classrooms
        const classroom_id_set = access_dict.classroom_id_set
        const library_list = this.$store.getters.get_library_list
        library_list.forEach((library) => {
          const classroom_id = library['classroom_id']
          if (classroom_id != null && classroom_id_set.has(classroom_id)) {
            this.library_id_list.push(library['library_id'])
          }
        })
      } else {
        // Get all libraries that this user has access to
        const library_id_set = this.$store.getters.get_user_library_dict[this.user_id]
        if (library_id_set && library_id_set.size > 0) {
          library_id_set.forEach(library_id => this.library_id_list.push(library_id))
        }
      }

      this.library_id_list.sort((a, b) => this.library_dict[a].library_name.localeCompare(this.library_dict[b].library_name))
    },
    filter_roles(valid_roles, user_roles) {
      for (let i = 0; i < valid_roles.length; i++) {
        for (let k = 0; k < user_roles.length; k++) {
          if (valid_roles[i] == user_roles[k]) {
            return true
          }
        }
      }

      return false;
    },
    get_image_url(library_id) {
      return `${get_bucket('media')}/libraries/s/${library_id}.webp`
    },
    get_library_dict() {

      const library_dict = this.$store.getters.get_library_dict
      const library_id_list = Object.keys(library_dict)
      
      // Set default home
      this.library_dict = {}
      this.library_dict[0] = {
        'library_id': 0,
        'library_name': 'Alles',
        'location_name': 'Thuis'
      }

      for (let i = 0; i < library_id_list.length; i++) {
        const library_id = library_id_list[i]
        this.library_dict[library_id] = {
          'library_id': library_id,
          'library_name': library_dict[library_id]['library_name'],
          'location_name': library_dict[library_id]['location_name'],
        }
      }

      return this.library_dict
    },
    set_library_id_to(library_id_to) {
      this.library_id_to = library_id_to
    },
    add_library_id_from(library_id) {
      if (!this.library_id_list_from.includes(library_id)) {
        this.library_id_list_from.push(library_id)
      }
      this.library_picker_action = null
    },
    async submit(library_id) {
      this.library_picker_action(library_id)
      this.library_picker_action = null
    },
    go_to_move_books() {
      this.$router.replace({
        name: "BoekScanVerplaatsen",
        params: {
          library_id_to: this.library_id_to,
          library_id_string_from: this.library_id_list_from.join(",")
        }
      })
    }
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}


.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.rotate {
  animation: rotation .2s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.img-frame {
  width: 50px;
  height: 62px;
  object-fit: cover;
  border-radius: 2px;
}

.log-book-row-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.log-book-row-details p {
  margin: 0;
  font-size: 10px;
  color: #888888;
}
</style>