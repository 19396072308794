<template>
  <div>
    <oib-barcode-reader-book
    v-if="user_id"
    :user_id="user_id"
    :view_finder_border_color="is_adding ? 'var(--highlight-color)' : 'var(--negative-color)'"
    @decode="body => on_decode(body)"
    ref="barcode_reader"/>
    <popup-book
    :isbn13="isbn13"
    :title="book_title"
    :description="popup_description"
    :library_name="library_name"
    ref="popup_submit">
      <oib-button-icon class="revert" icon="restart_alt" style="font-size: 32px;" @click="revert"/>
    </popup-book>
    <popup ref="popup_revert" duration="2000">
      <p>Actie ongedaan gemaakt.</p>
    </popup>
  </div>
</template>

<script>
import OibBarcodeReaderBook from "../components/oib_barcode_reader_book.vue";
import OibButtonIcon from "../components/OibButtonIcon.vue"
import Popup from "../components/popup.vue"
import PopupBook from "../components/popup_book.vue"

import {get_libraries_books_copies, put_libraries_books_copies} from "../store/api/library.js"

export default {
  name: 'BoekScanToevoegen',
  props: {
    library_id_to: {
      type: String
    },
    library_id_string_from: {
      type: String
    }
  },
  components: { 
    OibBarcodeReaderBook,
    OibButtonIcon,
    Popup,
    PopupBook
  },
  data() {
    return {
      is_adding: true,
      isbn13: undefined,
      copy_prev: null,
      book_type: null,
      avi: null,
      book_title: "",
      popup_description: "",
      api_call_in_progress: false,
      library_name: "",
      library_id_to_int: null
    }
  },
  computed: {
    user_id() {
      const user = this.$store.getters.getUser
      if (!user) {
        return
      }
      return user['user_id']
    }
  },
  methods: {
    async on_decode(body) {

      // Stop heavy image processing while running API calls
      this.$refs.barcode_reader.pause()

      this.isbn13 = body['isbn13']
      this.book_title = body['book_title']

      // Add or delete book
      await this.move_book(this.isbn13)

      // Continue heavy image processing
      this.$refs.barcode_reader.continue()
    },
    async move_book(isbn13) {
      const jwt = this.$store.getters.get_jwt
      const library_dict = this.$store.getters.get_library_dict
      const copy_list = await this.get_copy_list(jwt, isbn13, this.library_id_string_from)
      
      if (copy_list.length > 0) {
        // Move copy
        this.copy = copy_list[0]
        const response = await put_libraries_books_copies(
          jwt,
          this.copy["library_id"],
          isbn13,
          this.copy["copy_id"],
          this.library_id_to_int,
          isbn13,
          this.copy["addition_datetime"]          
        )

        // Show popup
        if (response.status == 204) {
          const library_name_old = library_dict[this.copy["library_id"]]["library_name"]
          const library_name_new = library_dict[this.library_id_to_int]["library_name"]
          this.popup_description = `Kopie verplaatst van ${library_name_old} naar ${library_name_new}`
          this.$refs.popup_submit.show()
        }
      } else {
        this.popup_description = `Geen kopie gevonden`
        this.$refs.popup_submit.show()
      }  
    },
    async get_copy_list(jwt, isbn13, library_id_string) {
      const json = await get_libraries_books_copies(jwt, {
        'library_id': library_id_string,
        'isbn13': isbn13,
        'limit': 100000,
        'page': 0
      })
      return json['copy_list']
    },
    async revert() {
      const jwt = this.$store.getters.get_jwt
      const library_dict = this.$store.getters.get_library_dict

      const response = await put_libraries_books_copies(
          jwt,
          this.library_id_to_int,
          this.copy["isbn13"],
          this.copy["copy_id"],
          this.copy["library_id"],
          this.copy["isbn13"],
          this.copy["addition_datetime"]          
        )

        // Show popup
        if (response.status == 204) {
          const library_name_old = library_dict[this.copy["library_id"]]["library_name"]
          const library_name_new = library_dict[this.library_id_to_int]["library_name"]
          this.popup_description = `Kopie verplaatst van ${library_name_old} naar ${library_name_new}`
          this.$refs.popup_submit.show()
        }
      
      if (response.status == 204 || response.status == 201) {
        this.$refs.popup_submit.hide()
        setTimeout(() => {
          this.$refs.popup_revert.show()
        }, 1000);
      }
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    if (!this.$store.getters.getUser) {
      return
    }

    this.library_id_to_int = parseInt(this.library_id_to)
  }
}
</script>

<style scoped>
.thumb-button {
  background-color: var(--highlight-color);
  border-radius: 100%;
  padding: 10px;
  color: white;
  margin: 10px;
}

.thumb-button.grey{
  filter: grayscale(100%);
  color: var(--contrast-color);
}

.popup h2 {
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 180px; 
  height: 1.2em; 
  white-space: nowrap;
}

.popup p {
  font-size: 0.8em;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

h2 {
  font-size: 1em;
  margin: 0;
}

.spacer {
  margin-bottom: 5px;
}

p {
  margin: 0;
  color: var(--primary-color)
}
</style>
