<template>
    <section class="book-info" :class="$mq">
      <div class="transition">
        <div class="wrapper column">
          <oib-header-book
          :isbn13="isbn13"
          :user_id="user_id"
          :book_title="book_title"
          :author_name="author_name"
          style="padding-bottom: 50px"
          />
          <div class="row" style="justify-content: space-between; padding-bottom: 30px;">
            <oib-button
              shape="rounded"
              v-if="!canFinish && !finished"
              v-on:click="submitValue"
              :disabled="!canUpdate"
              >Update
            </oib-button>
            <oib-button shape="rounded" v-if="canFinish" @click="finishBook"> Boek uit </oib-button>
            <oib-button-quit-book v-if="!finished" @click="reviewBook" />
          </div>
        </div>
        <div class="wrapper progress-form">
          <vue-slider v-model="value" :height="slider_height" :dotSize="slider_dotsize" :min="1" :max="page_count ? page_count : 1000" style="max-width: 90%; margin-left: 5%;"/>
          <div class="progress-form-controls">
            <button
              :disabled="finished"
              class="fade"
              v-on:click="updateValue(-10)"
            >
              -10
            </button>

            <button
              :disabled="finished"
              class="fade"
              v-on:click="updateValue(-1)"
            >
              -1
            </button>

            <div style="display: flex; flex-direction: row; flex-align-items: center; justify-content: center;">
              <p>{{ value }} / {{ page_count }}</p>
            </div>
            <button
              :disabled="finished"
              class="fade"
              v-on:click="updateValue(+1)"
            >
              +1
            </button>

            <button
              :disabled="finished"
              class="fade"
              v-on:click="updateValue(10)"
            >
              +10
            </button>
          </div>
          <a
          v-if="can_edit_page_count"
          style="font-size: 0.8em; font-weight: 400; color: var(--contrast-color-p3); margin-top: 10px; padding: 10px;"
          @click="go_to_book_vote()"
          >
          Pagina's aanpassen
          </a>
        </div>
      </div>
    </section>
</template>

<script>
import OibHeaderBook from '../components/oib_header_book.vue'
import OibButton from "./OibButton.vue";
import OibButtonQuitBook from './oib_button_quit_book.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

import {load_book_progress} from "../store/book_progress"
import {add_progress} from "../store/book_progress"
import {finish_book} from "../store/book_progress"
import {handle_total_pages_change} from "../store/book_progress"
import {on_done_isbn13_router} from "../store/click"

export default {
  name: "BookProgress",
  props: ["user_id", "isbn13"],
  components: {
    OibHeaderBook,
    OibButton,
    OibButtonQuitBook,
    VueSlider,
  },
  data() {
    return {
      value: 1,
      finished: false,
      progress: [],
      book_title: "",
      author_name: "",
      page_count: 1000
    };
  },
  computed: {
    can_edit_page_count() {
      // Skip if not logged in
      const user = this.$store.getters.getUser
      if (!user) {
        return
      }

      const role_list = user['role']
      return this.filter_roles(['TEACHER', 'LIBRARIAN', 'READING_CONSULTANT', 'SYSTEM_ADMIN'], role_list)
    },
    canUpdate() {
      // Do not compute if progress is undefined
      if (this.progress.length == 0) {
        return ""
      }

      if (this.isbn13==0) return false;
      return this.value > this.progress[0].pages_read;
    },
    canFinish() {
      // Do not compute if book is undefined
      if (!this.book) {
        return ""
      }

      if (this.isbn13==0) return false;
      return this.value == this.book.page_count && !this.finished;
    },
    btnActionText() {
      if (this.isbn13==0) {
        return "false";
      } else if (this.finished) {
        return "Geef mening";
      } else if (this.value == this.book.page_count) {
        return "Klaar";
      } else {
        return "Update";
      }
    },
    book() {
      return this.$store.getters.getBook(this.isbn13);
    },
    slider_height() {
      if (this.$mq == 'desktop') {
        return 8
      }
      return 10;
    },
    slider_dotsize() {
      return this.$mq == 'desktop' ? 25 : 30
    }
  },
  async created() {
    // Skip on logout
    if (!this.$store.getters.getUser) {
      return
    }

    const book = await this.$store.dispatch("load_book", this.isbn13)
    if (!book) {
      return
    }

    this.book_title = book['book_title']
    this.author_name = book['author_name']

    // Load progress
    await load_book_progress(this.user_id, this.isbn13)
    this.progress = this.$store.getters.get_progress_dict[this.user_id][this.isbn13]
    if (this.isbn13==0) this.value = 0;
    else this.value = this.progress[0].pages_read;

    // Set page count
    await this.set_page_count()
  },
  methods: {
    async set_page_count() {
      // Do not compute if book is undefined
      if (!this.book) {
        return
      }

      this.page_count = this.book.page_count

      // Check if progress total_pages has mismatch with actual page_count of book
      for (let progress of this.progress) {
        if (progress.pages_total != this.page_count) {
          await handle_total_pages_change(this.user_id, this.isbn13, this.page_count)
          break
        }
      }
    },
    updateValue(change) {
      if (this.isbn13==0) return;
      this.value = Math.max(
        Math.min(this.book.page_count, this.value + change),
        this.progress[0].pages_read
      );
    },
    async finishBook() {
      if (this.isbn13==0) return;
      await this.submitValue();
      this.finished = true;

      on_done_isbn13_router(
        this.user_id,
        this.book['book_edition_id'],
        this.isbn13,
        Object.keys(this.$store.getters.get_library_dict)
      )
    },
    async submitValue() {
      if (this.isbn13==0) return;

      const finished = this.book.page_count == this.value
      if (finished) {
        await finish_book(parseInt(this.user_id), this.isbn13, this.book.page_count)
      } else {
        await add_progress(parseInt(this.user_id), this.isbn13, this.value, this.book.page_count)
      }      
    },
    reviewBook() {
      // Update page count if update is pending, but update was not pressed
      if (this.canUpdate) {
        this.submitValue()
      }

      this.$router.replace({
        name: "BoekMeningStoppen",
        params: {
          user_id: this.user_id,
          isbn13: this.isbn13,
        },
      });
    },
    filter_roles(valid_roles, user_roles) {
      for (let i = 0; i < valid_roles.length; i++) {
        for (let k = 0; k < user_roles.length; k++) {
          if (valid_roles[i] == user_roles[k]) {
            return true
          }
        }
      }

      return false;
    },
    goToBook() {
      on_done_isbn13_router(
        this.user_id,
        this.book['book_edition_id'],
        this.isbn13,
        Object.keys(this.$store.getters.get_library_dict)
      )
    },    
    go_to_book_vote() {
      this.$router.push({
        name: 'BoekStemmenPaginas',
        params: {
          user_id: this.$store.getters.getUser['user_id'],
          isbn13: this.isbn13
        }
      });
    }   
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  watch: {
    isbn13 : function() {
      this.value = this.progress[0].pages_read;
    },
    book() {
      this.set_page_count()
    }
  },
};
</script>

<style scoped>
/* Flex-box */
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.book-info {
  z-index: 1
}

.desktop .book-info {
  min-width: 400px;
}

.mobile .book-info .transition {
  background: rgb(27,23,37);
  background: linear-gradient(0deg, rgba(27,23,37,1) 0%, rgba(27,23,37,1) 63%, rgba(9,9,121,0) 100%, rgba(0,212,255,1) 100%);
  background-size: 100% 1200px;
  background-repeat: no-repeat;
  margin-top: 20vh;
  padding-top: 200px;
  height: 100%;
}

.mobile .book-info .body {
  background: rgb(27,23,37);
}

.progress-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.progress-form-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  margin: 30px auto auto auto;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.progress-form-controls button {
  background-color: transparent;
  color: var(--contrast-color);
  width: 50px;
}

.progress-form-controls button:hover {
  background-color: transparent;
  color: var(--highlight-color);
}

.progress-form-controls button:disabled {
  background-color: transparent;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.progress-form-controls p {
  margin: 0;
  color: var(--contrast-color);
}
</style>