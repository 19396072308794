<template>
  <div>
    <h2>{{book_title}}</h2>
    <p class="author">{{author_name}}</p>
    <div class="row" style="justify-content: space-between;">
      <oib-rating :rating="rating" style="color: white"/>
      <oib-button-add-to-wishlist style="font-size: 24px;" :user_id="user_id" :isbn13="isbn13"/>
    </div>
  </div>
</template>

<script>
import OibRating from './oib_rating.vue'
import OibButtonAddToWishlist from '../components/oib_button_add_to_wishlist.vue'

export default {
  name: 'OibHeaderBook',
  props: ['isbn13', 'user_id', 'book_title', 'author_name'],
  components: {
    OibRating,
    OibButtonAddToWishlist
  },
  data () {
    return {
      rating: 4,
    }
  }
};
</script>

<style scoped>
/* Header */
h2 {
  margin: 0;
}

.desktop h2 {
  font-size: 50px;
}

.desktop .author {
  font-size: 20px;
}

</style>
