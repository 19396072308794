<template>
  <div>
    <oib-barcode-reader-book
    v-if="user_id"
    :user_id="user_id"
    :view_finder_border_color="is_adding ? 'var(--highlight-color)' : 'var(--negative-color)'"
    @decode="body => on_decode(body)"
    ref="barcode_reader"/>
    <div style="z-index: 10; display: block; bottom: 0px; right: 5px; display: flex; flex-direction: column; position: fixed;">
      <oib-button-icon
      icon="delete"
      style="font-size: 30px; margin-bottom: 0; background-color: var(--negative-color);"
      class="thumb-button"
      :class="is_adding ? 'grey' : 'highlight'"
      @click="is_adding = false"
      />
      <oib-button-icon
      icon="add"
      style="font-size: 30px;"
      class="thumb-button"
      :class="!is_adding ? 'grey' : 'highlight'"
      @click="is_adding = true"
      />
    </div>
    <popup-book
    :isbn13="isbn13"
    :title="book_title"
    :description="popup_description"
    :library_name="library_name"
    ref="popup_submit">
      <oib-button-icon class="revert" icon="restart_alt" style="font-size: 32px;" @click="revert"/>
    </popup-book>
    <popup ref="popup_revert" duration="2000">
      <p>Actie ongedaan gemaakt.</p>
    </popup>
  </div>
</template>

<script>
import OibBarcodeReaderBook from "../components/oib_barcode_reader_book.vue";
import OibButtonIcon from "../components/OibButtonIcon.vue"
import Popup from "../components/popup.vue"
import PopupBook from "../components/popup_book.vue"

import {get_libraries_books_copies} from "../store/api/library.js"
import {post_libraries_books_copies} from "../store/api/library.js"
import {delete_libraries_books_copies} from "../store/api/library.js"
import { get_book_products_users_scrape_data } from "../store/api/book_scraper"
import { put_book_products_users_scrape_data } from "../store/api/book_scraper"

export default {
  name: 'BoekScanToevoegen',
  components: { 
    OibBarcodeReaderBook,
    OibButtonIcon,
    Popup,
    PopupBook
  },
  data() {
    return {
      is_adding: true,
      isbn13: undefined,
      copy_id: null,
      book_type: null,
      avi: null,
      book_title: "",
      popup_description: "",
      api_call_in_progress: false,
      library_name: ""
    }
  },
  computed: {
    user_id() {
      const user = this.$store.getters.getUser
      if (!user) {
        return
      }
      return user['user_id']
    }
  },
  methods: {
    async on_decode(body) {

      // Stop heavy image processing while running API calls
      this.$refs.barcode_reader.pause()

      this.isbn13 = body['isbn13']
      this.book_title = body['book_title']

      // Add or delete book
      if (this.is_adding) {
        await this.add_book(this.isbn13)
      }
      else {
        await this.delete_book(this.isbn13)
      }

      // Continue heavy image processing
      this.$refs.barcode_reader.continue()
    },
    async add_book(isbn13) {
      const jwt = this.$store.getters.get_jwt
      const library_id = this.$store.getters.get_active_library_id
      const copy_list = await this.get_copy_list(jwt, isbn13, library_id)
      const copy_count = copy_list.length
      this.popup_description = `Kopie ${copy_count + 1} is toegevoegd aan`

      // Add book to library
      const response = await post_libraries_books_copies(jwt, library_id, isbn13)
      if (response.status == 201) {
        const response_json = await response.json();
        this.copy_id = response_json['copy_id']
        this.$refs.popup_submit.show()

        this.vote_book_type(isbn13)
      }      
    },
    async delete_book(isbn13) {
      
      const jwt = this.$store.getters.get_jwt
      const library_id = this.$store.getters.get_active_library_id
      const copy_list = await this.get_copy_list(jwt, isbn13, library_id)
      const copy_count = copy_list.length

      if (copy_count > 0) {
        // Delete book from library
        this.copy_id = copy_list[0]['copy_id']
        this.popup_description = `Kopie ${copy_count} is verwijderd uit`
        const response = await delete_libraries_books_copies(jwt, library_id, isbn13, this.copy_id)
        if (response.status == 204) {
          this.$refs.popup_submit.show()
        }
      }
      else {
        this.popup_description = `Geen kopie gevonden.`
        this.$refs.popup_submit.show()
      }
      
    },
    async get_copy_list(jwt, isbn13, library_id) {
      const json = await get_libraries_books_copies(jwt, {
        'library_id': library_id,
        'isbn13': isbn13,
        'limit': 100000,
        'page': 0
      })
      return json['copy_list']
    },
    async revert() {
      const jwt = this.$store.getters.get_jwt
      const library_id = this.$store.getters.get_active_library_id

      let response = undefined
      if (this.is_adding) {
        response = await delete_libraries_books_copies(jwt, library_id, this.isbn13, this.copy_id)
      }
      else {
        response = await post_libraries_books_copies(jwt, library_id, this.isbn13)
      }
      
      if (response.status == 204 || response.status == 201) {
        this.$refs.popup_submit.hide()
        setTimeout(() => {
          this.$refs.popup_revert.show()
        }, 1000);
      }
    },
    async vote_book_type(isbn13) {
      
      // Skip if 
      if (this.book_type == null) {
        return
      }

      let scrape_data = {
          'book_title': null,
          'book_type': null,
          'author_name': null,
          'series_title': null,
          'release_number': null,
          'book_language': null,        
          'dyslexia': null,
          'avi': null,
          'min_age': null,
          'max_age': null,
          'publisher_name': null,
          'binding_method': null,
          'page_count': null,
          'release_date': null,
          'cover': null,
          'tags': []
        }

      const jwt = this.$store.getters.get_jwt
      const response = await get_book_products_users_scrape_data(jwt, this.isbn13, this.user_id)
      if (response.status == 200) {
        scrape_data = await response.json()
      }

      await put_book_products_users_scrape_data(
          jwt,
          isbn13,
          this.user_id,
          scrape_data.book_title,
          this.book_type,
          scrape_data.author_name,
          scrape_data.series_title,
          scrape_data.release_number,
          scrape_data.book_language,
          scrape_data.dyslexia,
          this.avi,
          scrape_data.min_age,
          scrape_data.max_age,
          scrape_data.publisher_name,
          scrape_data.binding_method,
          scrape_data.page_count,
          scrape_data.release_date,
          null,
          scrape_data.tags,
          scrape_data.cover
      )
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    if (!this.$store.getters.getUser) {
      return
    }

    this.is_adding = this.$route.meta.initial_scan_mode == 'toevoegen'

    const library_id = this.$store.getters.get_active_library_id
    const library = this.$store.getters.get_library_dict[library_id]
    this.library_name = library.library_name

    // Detect library type
    const library_name_parsed = this.library_name.toLowerCase()

    // BOOK_TYPE
    const reading_book_list = [
      ' leesboek',
      '^leesboek',
      '^a ',
      ' a$',
      ' a ',
      '^a-',
      '-a$',
      ' a-',
      '-a ',
      '^b ',
      ' b$',
      ' b ',
      '^b-',
      '-b$',
      ' b-',
      '-b ',
      '^c ',
      ' c$',
      ' c ',
      '^c-',
      '-c$',
      ' c-',
      '-c ',
      'start',
      '- s$',
      'm3',
      'e3',
      'm4',
      'e4',
      'm5',
      'e5',
      'm6',
      'e6',
      'm7',
      'e7',
      'plus'
    ]

    if (library_name_parsed.includes('info')
    || library_name_parsed.includes('docu')) {
      this.book_type = 'INFORMATIEF'
    } else if (library_name_parsed.includes('prent')) {
      this.book_type = 'PRENTENBOEK'
    } else if (library_name_parsed.includes('voorle')) {
      this.book_type = 'VOORLEESBOEK'
    } else if (library_name_parsed.includes('strip')) {
      this.book_type = 'STRIPBOEK'
    } else if (library_name_parsed.includes('gedicht')
    || library_name_parsed.includes('poëzie')
    || library_name_parsed.includes('poezie')) {
      this.book_type = 'DICHTBUNDEL'
    } else if (library_name_parsed.includes('samen')
    || library_name_parsed.includes('duo')
    || library_name_parsed.includes('toneel')) {
      this.book_type = 'SAMENLEESBOEK'
    } else if (library_name_parsed.includes('ortho')
    || library_name_parsed.includes('vakdidactiek')
    || library_name_parsed.includes('vakliteratuur')) {
      this.book_type = 'VAKLITERATUUR'
    } else if (library_name_parsed.includes('makkelijk')) {
      this.book_type = 'MAKKELIJK_LEESBOEK'
    }

    if (this.book_type == null) {
      reading_book_list.forEach(search_key => {
        if (library_name_parsed.match(search_key)) {
          this.book_type = 'LEESBOEK'
        }
      })
    }

    // AVI
    const avi_map = {
      'avi start': 'START',
      'avi-start': 'START',
      'm3': 'M3',
      'e3': 'E3',
      'm4': 'M4',
      'e4': 'E4',
      'm5': 'M5',
      'e5': 'E5',
      'm6': 'M6',
      'e6': 'E6',
      'm7': 'M7',
      'e7': 'E7',
      'avi plus': 'PLUS',
      'avi-plus': 'PLUS'
    }

    const keys = Object.keys(avi_map)
    keys.forEach(search_key => {
      if (library_name_parsed.match(search_key)) {
        this.avi = avi_map[search_key]
      }
    })

    if (this.book_type == 'LEESBOEK' && ['START', 'M3', 'E3', 'M4'].includes(this.avi)) {
      this.book_type = 'EERSTE_LEESBOEK'
    }
  }
}
</script>

<style scoped>
.thumb-button {
  background-color: var(--highlight-color);
  border-radius: 100%;
  padding: 10px;
  color: white;
  margin: 10px;
}

.thumb-button.grey{
  filter: grayscale(100%);
  color: var(--contrast-color);
}

.popup h2 {
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 180px; 
  height: 1.2em; 
  white-space: nowrap;
}

.popup p {
  font-size: 0.8em;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

h2 {
  font-size: 1em;
  margin: 0;
}

.spacer {
  margin-bottom: 5px;
}

p {
  margin: 0;
  color: var(--primary-color)
}
</style>
