<template>
  <oib-page>
    <oib-nav-student title="Bieb menu"/>
    <oib-content style="padding-bottom: 100px;">
      <div v-if="has_role(['SYSTEM_ADMIN'])">
        <div class="wrapper column">
          <h2 class="h2">Boeken</h2>
        </div>
        <oib-menu-item
          description="Voeg een nieuw boek toe aan het boekenbestand van Onderwijs in Beeld."
          @click="go_to_route('BoekToevoegen', {'isbn13': null})"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="corporate_fare"
            title="Boek toevoegen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          description="Pas de kleuren van de thema's aan."
          @click="go_to_route('ThemaBeheren')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="bookmark"
            title="Thema's beheren"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
      </div>
      <div v-if="has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN']) ||
                (has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
      >
        <div class="wrapper column">
          <h2 class="h2">Bibliotheek</h2>
        </div>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Voeg een nieuwe bibliotheek toe aan jullie school."
          @click="go_to_route('BibliotheekToevoegen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="corporate_fare"
            title="Toevoegen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          description="Voeg boeken toe aan een schoolbibliotheek door barcodes van boeken te scannen."
          @click="go_to_library_route('BoekScanToevoegen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="add"
            icon_family="material-symbols-outlined"
            title="Boeken inscannen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          description="Verplaats boeken van één of meerdere bibliotheken naar een andere bibliotheek door barcodes van boeken te scannen."
          @click="go_to_library_route_multi('BoekScanVerplaatsen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="swap_horiz"
            icon_family="material-symbols-outlined"
            title="Boeken verplaatsen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          description="Verwijder boeken uit een schoolbibliotheek door barcodes van boeken te scannen."
          @click="go_to_library_route('BoekScanVerwijderen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="delete"
            icon_family="material-symbols-outlined"
            title="Boeken verwijderen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Verander de vormgeving van een schoolbibliotheek in de app."
          @click="go_to_library_route('BibliotheekAanpassenVormgeving')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="palette"
            title="Vormgeving aanpassen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Welke klassen mogen lenen uit welke bibliotheken?"
          @click="go_to_library_route('BibliotheekAanpassenToegang')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="security"
            title="Toegang beheren"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"   
          description="Welke klassen mogen zelfstandig boeken lenen?"
          @click="go_to_route('BibliotheekAanpassenZelfstandigheid')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="lock"
            title="Zelfstandig lenen beheren"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Voeg boeken toe via een Excel bestand."
          @click="go_to_route('BibliotheekUploaden')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="upload"
            title="Upload schoolbieb"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Download de schoolbieb als Excel bestand."
          @click="go_to_route('BibliotheekDownloaden')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="download"
            title="Download schoolbieb"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Lever alle boeken in een schoolbibliotheek in."
          @click="go_to_route('BibliotheekHerstellen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="restore"
            title="Herstellen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="(has_role(['BOOKSHOP_OWNER', 'READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Verhuis een bibliotheek naar een andere school."
          @click="go_to_library_route('BibliotheekVerplaatsenOrganisatie')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="local_shipping"
            title="Verhuis bibliotheek"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN']) ||
               (has_role(['READING_CONSULTANT', 'SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Verwijder een schoolbibliotheek."
          @click="go_to_library_route('BibliotheekVerwijderen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="delete"
            title="Verwijderen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
      </div>

      <div v-if="has_role(['LIBRARIAN', 'SCHOOL_ADMIN']) ||
                (has_role(['SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
      >
        <div class="wrapper column">
          <h2 class="h2">School</h2>
        </div>
        <oib-menu-item
          v-if="has_role(['LIBRARIAN', 'SCHOOL_ADMIN']) ||
                (has_role(['SYSTEM_ADMIN']) && $store.getters.get_school_id_active)"
          description="Wijzig de naam van jullie school."
          @click="go_to_route('OrganisatieAanpassen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="edit"
            title="Naam wijzigen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['SYSTEM_ADMIN']) && $store.getters.get_school_id_active"
          description="Voeg adres toe aan jullie school."
          @click="go_to_route('OrganisatieAdresToevoegen')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="add_location"
            title="Adres toevoegen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
        <oib-menu-item
          v-if="has_role(['SYSTEM_ADMIN']) && $store.getters.get_school_id_active"
          description="Wijzig het adres van jullie school."
          @click="go_to_route('OrganisatieAanpassenAdres')"
          hover="hover"
          >
          <template v-slot:title>
            <oib-icon-label
            style="color: white;"
            icon="edit_location"
            title="Adres wijzigen"
            direction="row"
            :active="false"
            />
          </template>
        </oib-menu-item>
      </div>
      <div class="wrapper column">
        <h2 class="h2">Voor de ontwikkelaar</h2>
      </div>
      <oib-menu-item
        description="Activeer de ontwikkelaarsmodus om inzicht te krijgen in fout analyses (alleen voor ontwikkelaars)."
        >
        <template v-slot:title>
          <oib-icon-label
          style="color: white;"
          icon="delete"
          title="Problemen onderzoeken"
          direction="row"
          :active="false"
          />
        </template>
        <template v-slot:right>
          <oib-checkbox
          :set_checked="$store.getters.get_debug_mode"
          @click="set_debug_mode(!$store.getters.get_debug_mode)"
          />
        </template>
      </oib-menu-item>     
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibMenuItem from "../components/oib_menu_item.vue"
import OibIconLabel from "../components/oib_icon_label.vue"
import OibCheckbox from '../components/OibCheckbox.vue'

import { go_to_route } from "../store/navigation";
import { has_role } from "../store/utils";

export default {
  name: "Instellingen",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibMenuItem,
    OibIconLabel,
    OibCheckbox
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    // Import
    go_to_route,
    has_role,

    // Local
    set_debug_mode(value) {
      this.$store.commit('set_debug_mode', value)
    },
    go_to_library_route(route) {
      go_to_route('BibliotheekKiezenRouter', {
        'user_id': this.$store.getters.getUser['user_id'],
        'library_route': route
      })
    },
    go_to_library_route_multi(route) {
      go_to_route('BibliotheekKiezenRouterMulti', {
        'user_id': this.$store.getters.getUser['user_id'],
        'library_route': route
      })
    }
  }
};
</script>

<style scoped>
.checkbox-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
}

.checkbox-option .wrapper-checkbox {
  padding-right: 10px;
}
</style>