import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import OntdekkenPartners from '../views/OntdekkenPartners.vue'
import PartnerKoppelen from '../views/PartnerKoppelen.vue'
import BibliotheekCollectie from '../views/BibliotheekCollectie.vue'
import BibliotheekLezen from '../views/BibliotheekLezen.vue'
import BibliotheekZoeken from '../views/BibliotheekZoeken.vue'
import BibliotheekZoekenFilter from '../views/BibliotheekZoekenFilter.vue'
import BibliotheekZoekenThema from '../views/BibliotheekZoekenThema.vue'
import BibliotheekZoekenSeries from '../views/BibliotheekZoekenSeries.vue'
import BibliotheekKiezenRouter from '../views/BibliotheekKiezenRouter.vue'
import BibliotheekKiezenRouterMulti from '../views/BibliotheekKiezenRouterMulti.vue'
import BibliotheekKiezenGroep from '../views/BibliotheekKiezenGroep.vue'
import BibliotheekToevoegen from '../views/BibliotheekToevoegen.vue'
import BibliotheekAanpassenVormgeving from '../views/BibliotheekAanpassenVormgeving.vue'
import BibliotheekAanpassenToegang from '../views/BibliotheekAanpassenToegang.vue'
import BibliotheekAanpassenZelfstandigheid from '../views/BibliotheekAanpassenZelfstandigheid.vue'
import BibliotheekVerwijderen from '../views/BibliotheekVerwijderen.vue'
import BibliotheekHerstellen from '../views/BibliotheekHerstellen.vue'
import BoekLenen from '../views/BoekLenen.vue'
import BoekBiebKiezen from '../views/BoekBiebKiezen.vue'
import BibliotheekBoekVerplaatsen from '../views/BibliotheekBoekVerplaatsen.vue'
import BibliotheekBoekVerwijderen from '../views/BibliotheekBoekVerwijderen.vue'
import BibliotheekUploaden from '../views/BibliotheekUploaden.vue'
import BibliotheekDownloaden from '../views/BibliotheekDownloaden.vue'
import BibliotheekVerplaatsenOrganisatie from '../views/BibliotheekVerplaatsenOrganisatie.vue'
import Boek from '../views/Boek.vue'
import BoekVoortgang from '../views/BoekVoortgang.vue'
import BoekScan from '../views/BoekScan.vue'
import BoekScanToevoegen from '../views/BoekScanToevoegen.vue'
import BoekScanVerplaatsen from '../views/BoekScanVerplaatsen.vue'
import BoekScanKiezen from '../views/BoekScanKiezen.vue'
import BoekToevoegen from '../views/BoekToevoegen.vue'
import BoekStemmenPaginas from '../views/BoekStemmenPaginas.vue'
import BoekStemmenKaft from '../views/BoekStemmenKaft.vue'
import BoekStemmenDoelgroep from '../views/BoekStemmenDoelgroep.vue'
import BoekStemmenTrefwoorden from '../views/BoekStemmenTrefwoorden.vue'
import OverzichtLeerling from '../views/OverzichtLeerling.vue'
import GebruikersLeerlingen from '../views/GebruikersLeerlingen.vue'
import GebruikersLeerkrachten from '../views/GebruikersLeerkrachten.vue'
import OverzichtLeerlingenQrCode from '../views/OverzichtLeerlingenQrCode.vue'
import GroepKiezen from '../views/GroepKiezen.vue'
import Login from '../views/Login.vue'
import LoginQrCode from '../views/LoginQrCode.vue'
import LoginGoogle from '../views/LoginGoogle.vue'
import LoginMicrosoft from '../views/LoginMicrosoft.vue'
import LoginInstructions from '../views/LoginInstructions.vue'
import Instructie1 from '../views/Instructie1.vue'
import Instructie2 from '../views/Instructie2.vue'
import Instructie3 from '../views/Instructie3.vue'
import Opdracht from '../views/Opdracht.vue'
import Analyse from '../views/Analyse.vue'
import Meldingen from '../views/Meldingen.vue'
import Laden from '../views/Loading.vue'
import Missies from '../views/Missies.vue'
import Mening from '../views/Mening.vue'
import BoekMeningStoppen from '../views/BoekMeningStoppen.vue'
import Instellingen from '../views/Instellingen.vue'
import InstellingenQrCode from '../views/InstellingenQrCode.vue'
import LeesProfiel from '../views/LeesProfiel.vue'
import MenuBibliotheek from '../views/MenuBibliotheek.vue'
import MenuBoek from '../views/MenuBoek.vue'
import AviStopwatch from '../views/AviStopwatch.vue'
import Scholen from '../views/Scholen.vue'
import LvsKoppelenAutorisatie from '../views/LvsKoppelenAutorisatie.vue'
import LvsKoppelenGroepen from '../views/LvsKoppelenGroepen.vue'
import LvsNietMethodeToetsen from '../views/LvsNietMethodeToetsen.vue'
import LvsSyncBericht from '../views/LvsSyncBericht.vue'
import GebruikerVerwijderen from '../views/GebruikerVerwijderen.vue'
import Support from '../views/Support.vue'
import Handleidingen from '../views/Handleidingen.vue'
import Winkel from '../views/Winkel.vue'
import BiebhulpToevoegen from '../views/BiebhulpToevoegen'
import InlogmethodeToevoegen from '../views/InlogmethodeToevoegen'
import InlogmethodeKoppelen from '../views/InlogmethodeKoppelen'
import MenuLeerkrachtenToevoegen from '../views/MenuLeerkrachtenToevoegen.vue'
import MenuAviToetsToevoegen from '../views/MenuAviToetsToevoegen.vue'
import StelEenVraag from '../views/StelEenVraag.vue'
import Taken from '../views/Taken.vue'
import OrganisatieToevoegen from '../views/OrganisatieToevoegen.vue'
import OrganisatieToevoegenPartner from '../views/OrganisatieToevoegenPartner.vue'
import OrganisatieToevoegenSchool from '../views/OrganisatieToevoegenSchool.vue'
import ThemaBeheren from '../views/ThemaBeheren.vue'
import ThemaAanpassen from '../views/ThemaAanpassen.vue'
import OrganisatieAanpassen from '../views/OrganisatieAanpassen.vue'
import OrganisatieAanpassenAdres from '../views/OrganisatieAanpassenAdres.vue'
import OrganisatieAdresToevoegen from '../views/OrganisatieAdresToevoegen.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login/qr-code',
    name: 'LoginQrCode',
    component: LoginQrCode
  },
  {
    path: '/login/google',
    name: 'LoginGoogle',
    component: LoginGoogle
  },
  {
    path: '/login/microsoft',
    name: 'LoginMicrosoft',
    component: LoginMicrosoft
  },
  {
    path: '/login/instructies',
    name: 'LoginInstructions',
    component: LoginInstructions
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/support/stel-een-vraag',
    name: 'StelEenVraag',
    component: StelEenVraag
  },
  {
    path: '/support/handleidingen',
    name: 'Handleidingen',
    component: Handleidingen
  },
  {
    path: '/winkel',
    name: 'Winkel',
    component: Winkel
  },
  {
    path: '/gebruiker/:user_id/boek/voortgang/:isbn13',
    name: 'BoekVoortgang',
    component: BoekVoortgang,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/scan/zoeken/:fast_borrow',
    name: 'BoekScan',
    component: BoekScan,
    props: true
  },
  {
    path: '/boek/:isbn13/toevoegen',
    name: 'BoekToevoegen',
    component: BoekToevoegen,
    props: true
  },
  {
    path: '/boek/scan/kiezen',
    name: 'BoekScanKiezen',
    component: BoekScanKiezen
  },
  {
    path: '/boek/:isbn13/mening',
    name: 'Mening',
    component: Mening,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/:isbn13/mening/stoppen',
    name: 'BoekMeningStoppen',
    component: BoekMeningStoppen,
    props: true
  },
  {
    path: '/ontdekken/partners',
    name: 'OntdekkenPartners',
    component: OntdekkenPartners
  },
  {
    path: '/ontdekken/partners/:organisation_id/:organisation_name/koppelen',
    name: 'PartnerKoppelen',
    component: PartnerKoppelen,
    props: true
  },
  {
    path: '/bibliotheek/:library_id/scannen',
    name: 'BoekScanToevoegen',
    component: BoekScanToevoegen,
    props: true,
    meta: { initial_scan_mode: 'toevoegen'  }
  },
  {
    path: '/bibliotheek/:library_id_string_from/scannen/verplaatsen-naar/:library_id_to',
    name: 'BoekScanVerplaatsen',
    component: BoekScanVerplaatsen,
    props: true
  },
  {
    path: '/bibliotheek/:library_id/scannen',
    name: 'BoekScanVerwijderen',
    component: BoekScanToevoegen,
    props: true,
    meta: { initial_scan_mode: 'verwijderen'  }
  },
  {
    path: '/bibliotheek/menu',
    name: 'MenuBibliotheek',
    component: MenuBibliotheek
  },
  {
    path: '/boek/:isbn13/menu',
    name: 'MenuBoek',
    component: MenuBoek,
    props: true
  },
  {
    path: '/bibliotheek/kiezen/:user_id/:library_route',
    name: 'BibliotheekKiezenRouter',
    component: BibliotheekKiezenRouter,
    props: true
  },
  {
    path: '/bibliotheek/kiezen/:user_id/:library_route',
    name: 'BibliotheekKiezenRouterMulti',
    component: BibliotheekKiezenRouterMulti,
    props: true
  },
  {
    path: '/bibliotheek/kiezen/groep',
    name: 'BibliotheekKiezenGroep',
    component: BibliotheekKiezenGroep,
    props: true
  },
  {
    path: '/bibliotheek/collectie',
    name: 'BibliotheekCollectie',
    component: BibliotheekCollectie
  },
  {
    path: '/bibliotheek/zoeken',
    name: 'BibliotheekZoeken',
    component: BibliotheekZoeken
  },
  {
    path: '/bibliotheek/zoeken/filter/query=:query',
    name: 'BibliotheekZoekenFilter',
    component: BibliotheekZoekenFilter,
    props: true
  },
  {
    path: '/bibliotheek/zoeken/thema/:color/:tag_id/:tag_name/:tag_name_alias',
    name: 'BibliotheekZoekenThema',
    component: BibliotheekZoekenThema,
    props: true
  },
  {
    path: '/bibliotheek/zoeken/series/:series_id/:series_title/:color',
    name: 'BibliotheekZoekenSeries',
    component: BibliotheekZoekenSeries,
    props: true
  },
  {
    path: '/bibliotheek/toevoegen',
    name: 'BibliotheekToevoegen',
    component: BibliotheekToevoegen,
  },
  {
    path: '/bibliotheek/uploaden',
    name: 'BibliotheekUploaden',
    component: BibliotheekUploaden,
  },
  {
    path: '/bibliotheek/downloaden',
    name: 'BibliotheekDownloaden',
    component: BibliotheekDownloaden,
  },
  {
    path: '/bibliotheek/:library_id/aanpassen/vormgeving',
    name: 'BibliotheekAanpassenVormgeving',
    component: BibliotheekAanpassenVormgeving,
    props: true
  },
  {
    path: '/bibliotheek/:library_id/aanpassen/toegang',
    name: 'BibliotheekAanpassenToegang',
    component: BibliotheekAanpassenToegang,
    props: true
  },
  {
    path: '/bibliotheek/aanpassen/zelfstandigheid',
    name: 'BibliotheekAanpassenZelfstandigheid',
    component: BibliotheekAanpassenZelfstandigheid
  },
  {
    path: '/bibliotheek/:library_id/verwijderen',
    name: 'BibliotheekVerwijderen',
    component: BibliotheekVerwijderen,
    props: true
  },
  {
    path: '/bibliotheek/:library_id/verplaatsen/organisatie',
    name: 'BibliotheekVerplaatsenOrganisatie',
    component: BibliotheekVerplaatsenOrganisatie,
    props: true
  },
  {
    path: '/bibliotheek/herstellen',
    name: 'BibliotheekHerstellen',
    component: BibliotheekHerstellen,
  },
  {
    path: '/gebruiker/:user_id/verwijderen',
    name: 'GebruikerVerwijderen',
    component: GebruikerVerwijderen,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/:book_edition_id/:isbn13/:scanned',
    name: 'Boek',
    component: Boek,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/:book_edition_id/:isbn13_scanned/actie/:action',
    name: 'BoekBiebKiezen',
    component: BoekBiebKiezen,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/:book_edition_id/:isbn13/bibliotheek/:library_id',
    name: 'BoekLenen',
    component: BoekLenen,
    props: true
  },
  {
    path: '/gebruiker/:user_id/bibliotheek/boek/verplaatsen',
    name: 'BibliotheekBoekVerplaatsen',
    component: BibliotheekBoekVerplaatsen,
    props: true
  },
  {
    path: '/bibliotheek/boek/verwijderen',
    name: 'BibliotheekBoekVerwijderen',
    component: BibliotheekBoekVerwijderen
  },
  {
    path: '/gebruiker/:user_id/boek/:isbn13/stemmen/paginas',
    name: 'BoekStemmenPaginas',
    component: BoekStemmenPaginas,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/:isbn13/stemmen/kaft',
    name: 'BoekStemmenKaft',
    component: BoekStemmenKaft,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/:isbn13/stemmen/doelgroep',
    name: 'BoekStemmenDoelgroep',
    component: BoekStemmenDoelgroep,
    props: true
  },
  {
    path: '/gebruiker/:user_id/boek/:isbn13/stemmen/trefwoorden',
    name: 'BoekStemmenTrefwoorden',
    component: BoekStemmenTrefwoorden,
    props: true
  },
  {
    path: '/overzicht/leerling/:user_id',
    name: 'OverzichtLeerling',
    component: OverzichtLeerling,
    props: true
  },
  {
    path: '/gebruikers/leerlingen',
    name: 'GebruikersLeerlingen',
    component: GebruikersLeerlingen
  },
  {
    path: '/gebruikers/leerkrachten',
    name: 'GebruikersLeerkrachten',
    component: GebruikersLeerkrachten
  },
  {
    path: '/overzicht/leerlingen',
    name: 'OverzichtLeerlingenQrCode',
    component: OverzichtLeerlingenQrCode
  },
  {
    path: '/groep/kiezen',
    name: 'GroepKiezen',
    component: GroepKiezen
  },
  {
    path: '/bibliotheek/lezen',
    name: 'BibliotheekLezen',
    component: BibliotheekLezen,
  },
  {
    path: '/missies',
    name: 'Missies',
    component: Missies
  },
  {
    path: '/lezen/instructie/1',
    name: 'Instructie1',
    component: Instructie1,
  },
  {
    path: '/lezen/instructie/2',
    name: 'Instructie2',
    component: Instructie2,
  },
  {
    path: '/lezen/instructie/3',
    name: 'Instructie3',
    component: Instructie3,
  },
  {
    path: '/lezen/opdracht',
    name: 'Opdracht',
    component: Opdracht,
  },
  {
    path: '/analyse',
    name: 'Analyse',
    component: Analyse,
    props: true
  },
  {
    path: '/meldingen',
    name: 'Meldingen',
    component: Meldingen,
  },
  {
    path: '/laden',
    name: 'Laden',
    component: Laden,
  },
  {
    path: '/scholen',
    name: 'Scholen',
    component: Scholen,
  },
  {
    path: '/organisaties/toevoegen',
    name: 'OrganisatieToevoegen',
    component: OrganisatieToevoegen,
  },
  {
    path: '/organisaties/toevoegen/school',
    name: 'OrganisatieToevoegenSchool',
    component: OrganisatieToevoegenSchool,
  },
  {
    path: '/organisaties/toevoegen/partner/:organisation_type',
    name: 'OrganisatieToevoegenPartner',
    component: OrganisatieToevoegenPartner,
    props: true
  },
  {
    path: '/leerlingen/:user_id/toetsen/avi/opties',
    name: 'MenuAviToetsToevoegen',
    component: MenuAviToetsToevoegen,
    props: true
  },
  // {
  //   path: '/leerlingen/:user_id/toetsen/avi/handmatig',
  //   name: 'AviToetsToevoegenHandmatig',
  //   component: AviToetsToevoegenHandmatig,
  //   props: true
  // },
  {
    path: '/leerkrachten/toevoegen',
    name: 'MenuLeerkrachtenToevoegen',
    component: MenuLeerkrachtenToevoegen,
  },
  {
    path: '/lvs/:user_group/autorisatie',
    name: 'LvsKoppelenAutorisatie',
    component: LvsKoppelenAutorisatie,
    props: true
  },
  {
    path: '/lvs/leerlingen/activeren',
    name: 'LvsKoppelenGroepen',
    component: LvsKoppelenGroepen,
  },
  {
    path: '/lvs/toetsen/toevoegen/avi',
    name: 'LvsNietMethodeToetsen',
    component: LvsNietMethodeToetsen,
  },
  {
    path: '/lvs/syncbericht',
    name: 'LvsSyncBericht',
    component: LvsSyncBericht,
  },
  {
    path: '/overzicht/leerling/:user_id/instellingen',
    name: 'Instellingen',
    component: Instellingen,
    props: true
  },
  {
    path: '/overzicht/leerling/:user_id/instellingen/qr-code',
    name: 'InstellingenQrCode',
    component: InstellingenQrCode,
    props: true
  },
  {
    path: '/overzicht/leerling/:user_id/lees-profiel',
    name: 'LeesProfiel',
    component: LeesProfiel,
    props: true
  },
  {
    path: '/overzicht/leerling/:user_id/avi-stopwatch',
    name: 'AviStopwatch',
    component: AviStopwatch,
    props: true
  },
  {
    path: '/account-beheer/toevoegen/bieb-assistent',
    name: 'BiebhulpToevoegen',
    component: BiebhulpToevoegen
  },
  {
    path: '/account-beheer/gebruikers/:user_id/inlog-methode',
    name: 'InlogmethodeToevoegen',
    component: InlogmethodeToevoegen,
    props: true
  },
  {
    path: '/account-beheer/gebruikers/:user_id/inlog-methode/:account_type',
    name: 'InlogmethodeKoppelen',
    component: InlogmethodeKoppelen,
    props: true
  },
  {
    path: '/taken',
    name: 'Taken',
    component: Taken
  },
  {
    path: '/themas/beheren',
    name: 'ThemaBeheren',
    component: ThemaBeheren,
    props: true
  },
  {
    path: '/themas/:tag_id/aanpassen',
    name: 'ThemaAanpassen',
    component: ThemaAanpassen,
    props: true
  },
  {
    path: '/organisatie/naam/aanpassen',
    name: 'OrganisatieAanpassen',
    component: OrganisatieAanpassen
  },
  {
    path: '/organisatie/adres/aanpassen',
    name: 'OrganisatieAanpassenAdres',
    component: OrganisatieAanpassenAdres
  },
  {
    path: '/organisatie/adres/toevoegen',
    name: 'OrganisatieAdresToevoegen',
    component: OrganisatieAdresToevoegen
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  if (store.getters.getLocalChangesCount > 0) {
    if (!window.confirm("Er zijn niet opgeslagen aanpassingen. Weet je zeker dat je weg wilt gaan?")) {
      return;
    }
    store.commit("revertLocalChanges");
  }
  next();
});

router.afterEach((to) => {
  
  function gtag () {
    window.dataLayer.push(arguments)
  }
  gtag('event', 'screen_view', {
    'app_name': 'Onderwijs in Beeld - app',
    'screen_name': to.name
  });
});

export default router
